import React, { useState, useEffect, useRef } from "react";
import {
  Link,
  NavLink,
  useLocation,
  useHistory,
  Redirect,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import MobileCart from "pages/MobileCart";

import SideNav from "./SideNav";
import DateAndTime from "./DateAndTime";
import ModalWrapper from "../ModalWrapper";
import { ChangeAddressMobile, SearchList } from "../../pages";

import shoppingBagImg from "assets/images/shopping-bag-icon.svg";
import logoImg from "assets/images/food-dudes-dot-com.svg";
import cartImg from "assets/images/cart.svg";
// import notificationImg from "assets/images/notification.svg";
import profileImg from "assets/images/profile.svg";
import logoutImg from "assets/images/logout-lan.svg";
import menuImg from "assets/images/menu-icon.svg";
import searchFooterImg from "assets/images/search-footer.svg";

import {
  selectedAddress,
  setSelectedRest,
  headerTabSelected,
  setDraweState,
  setResturants,
  setCuisines,
  setUnSelectedRest,
  resetAll,
  getResturantsData,
  verifyAddress,
  initiateGroupOrder,
  setWarningModalStatus,
  setPrevAddress,
} from "redux-store";
import moment from "moment";
import OutsideClickHandler from "../OutsideClickHandler";
import $ from "jquery";
import { useWindowResize } from "../useWindowResize";
import NotificationDropdown from "./NotificationDropdown";

const Header = (props) => {
  const { invalidDates, needNotifications } = props;
  const isMobileOnly = useWindowResize();
  const [scheduledPickUpDropdown, setScheduledPickUpDropdown] = useState(false);
  const [addressDropdown, setAddressDropdown] = useState(false);
  const [showDateTime, setShowDateTime] = useState(false);

  const [scroll, setScroll] = useState(false);

  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);
 
  const handleScroll = () => {
    const scrollCheck = window.scrollY;
    if (scrollCheck > 1 && !scroll) {
      setScroll(true);
    } else if (scrollCheck == 0) {
      setScroll(false);
    }
  };
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  const restId = store.rest.restaurant_id;
  const selectedRestData = store.rest.selectedResturantData?.[restId];
  const menuSelected = store?.rest?.menus?.[restId];
  let match = useRouteMatch([
    "/restaurants/:restId/:groupToken",
    "/restaurants/:restId",
  ]);
  const location = useLocation();

  const { groupToken } = useParams();
  const { groupOrderInfo } = store.rest;
  const { cart } = store.groupCart;

  const groupOrderCartItems =
    cart.items && cart.items?.length
      ? [].concat
          .apply(
            [],
            cart.items?.map((it) => {
              if (it.items && it.items.length)
                return it.items?.map((it) => +it?.customers_basket_quantity);
              else return [0];
            })
          )
          ?.reduce((pt, nt) => pt + nt)
      : 0;

  const isItAdminLink = groupOrderInfo?.members;

  useEffect(() => {}, [match]);

  const { address_lat, address_lng, entry_street_address, entry_street_name, entry_suburb  } =
    store.profile.selectedAddress;
  // const [drawerState, setDraweState] = useState(false);
  const handleChoosenAddress = (x, latlong) => {
    const { address, city, state, country, postal_code, street_number, route } =
      x;
    const { lat, lng } = latlong;
    dispatch(
      selectedAddress({
        address_lat: lat,
        address_lng: lng,
        entry_street_address: address,
        entry_postcode: postal_code,
        entry_street_name:
          street_number && route
            ? street_number.concat(`, ${route}`)
            : street_number
            ? street_number
            : route
            ? route
            : "",
        entry_state: state,
        entry_city: city,
      })
    );
    if (
      location.pathname != "/restaurants" &&
      location.pathname != "/restaurants/"
    ) {
      dispatch(
        verifyAddress(
          {
            lat_from: lat.toString(),
            lng_from: lng.toString(),
            restaurant_id: store.rest.restaurant_id,
          }
          // ()=>{
          //  setRedirectPage(true)
          // }
        )
      );
    }
    // alert("called")
    setRecall(true);
  };
  const [redirectPage, setRedirectPage] = useState(false);
  const renderRedirect = () => {
    if (redirectPage) {
      return <Redirect to="/" />;
    }
    return null;
  };
  const [list, setList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleMenuSearch = (e) => {
    const value = e.target?.value?.trim();
    if (value) {
      if (/^[^a-zA-Z0-9]+$/.test(value)) {
        setList([]);
        setSearchValue(e.target.value);
        return;
      }
      let menus = [];
      menuSelected.forEach((item) => {
        menus.push(
          item.submenus.map((submenu) => ({
            ...submenu,
            type: item?.menu_title,
          }))
        );
      });
      const allDishes = menus.flat()?.reduce((prev, curr) => {
        return prev?.length ? prev.concat(curr.products) : curr.products;
      }, []);
      const searched = value
        .toLowerCase()
        .replace(/[&, -]/g, "|")
        .split("|");

      let searchedDished = searched
        ? searched.reduce((prev, curr, index) => {
            if (/[&, -]/g.test(curr)) {
              return prev;
            }
            if (index === 0) {
              return allDishes
                .filter((dish, index, array) => {
                  return (
                    (dish?.products_name.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) => t?.products_name === dish?.products_name
                      ) === index) ||
                    (dish?.products_description.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) =>
                          t?.products_description === dish?.products_description
                      ) === index)
                  );
                })
                .map((dish) => ({ ...dish, label: dish?.products_name }));
            } else {
              return prev
                .filter((dish, index, array) => {
                  return (
                    (dish?.products_name.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) => t?.products_name === dish?.products_name
                      ) === index) ||
                    (dish?.products_description.toLowerCase().includes(curr) &&
                      array.findIndex(
                        (t) =>
                          t?.products_description === dish?.products_description
                      ) === index)
                  );
                })
                .map((dish) => ({ ...dish, label: dish?.products_name }));
            }
          }, "")
        : [];
      setList(searchedDished);
    } else {
      setList([]);
    }
    setSearchValue(e.target.value);
  };

  const handleSearch = (e) => {
    const value = e.target.value;
    if (value) {
      const resturants = [...store.rest.resturants];
      const filteredResturants = resturants.filter((resturants) => {
        console.log("test restaurants",resturants)
        debugger
        const arr = resturants.restaurant_cuisines?.split(",");
        let x = false;
        arr?.map((item) => {
          if (item.toLowerCase().includes(value.toLowerCase())) {
            x = true;
          }
        });
        return resturants.name.toLowerCase().includes(value.toLowerCase()) || x;
      });
      setList(filteredResturants);
    } else {
      setList([]);
    }
    setSearchValue(e.target.value);
  };
  const goToMenu = (data) => {
    setList([]);
    setSearchValue("");
    dispatch(setSelectedRest(data));
    dispatch(setUnSelectedRest(data));
    // if (location.pathname == "/restaurants") {
    history.push({
      pathname: `/restaurants/${data.name.replace(/[^a-zA-Z0-9]+/g, "_")}_${
        data.restaurant_id
      }`,
      // pathname: `restaurants/${data.name}`,
      // search: '?query=abc',
      data: data,
    });
    // }
    $(".picktime .dropdown-menu").removeClass("show");
  };
  const wrapperRef = useRef(null);
  // useOutsideAlerter(wrapperRef, setList, setShowCart, setSearchValue);
  const [showCart, setShowCart] = useState(false);
  const wrapperRefTwo = useRef(null);
  // useOutsideAlerter(wrapperRefTwo, ()=>dispatch(setDraweState(false)), ()=>null);
  const handleLandingNav = (e) => {
    if (groupToken && !groupOrderInfo?.members && !groupOrderInfo?.is_admin) {
      dispatch(setWarningModalStatus(true));
      return;
    }

    if (
      location.pathname == "/restaurants" ||
      location.pathname == "/restaurants/"
    ) {
      dispatch(setPrevAddress({...store.profile.selectedAddress}))
      dispatch(selectedAddress({}));
      dispatch(setResturants([]));
    }
    dispatch({ type: "ROUTEPLACE", payload: false });
    history.push("/");
    // setTimeout(()=>$('header').addClass("sticky"), 0)
    window.scrollTo(0, 0);
  };
  const handleLandingFromCheckout = (e) => {
    if (groupToken && !groupOrderInfo?.members && !groupOrderInfo?.is_admin) {
      dispatch(setWarningModalStatus(true));
      return;
    }
    dispatch({ type: "ROUTEPLACE", payload: false });
    history.push("/");
    // setTimeout(()=>$('header').addClass("sticky"), 0)
    window.scrollTo(0, 0);
  };

  const [reCall, setRecall] = useState(false);
  useEffect(() => {
    if(window.pageYOffset>0){
      setScroll(true)
    }
    if (reCall) {
      if (Object.keys(store.profile.selectedAddress).length > 1) {
        dispatch(
          getResturantsData({
            lat_from: address_lat.toString(),
            lng_from: address_lng.toString(),
          })
        );
      }
      setRecall(false);
    }
    // }
  }, [reCall]);
  const handleTabs = (val) => {
    setRecall(true);
    if (groupToken) {
      let model = {
        id: groupOrderInfo?.id,
        name: groupOrderInfo?.title,
        limit: groupOrderInfo?.amount_limit,
        member: groupOrderInfo?.members,
        restaurant_id: groupOrderInfo?.restaurant_id,
        lat: groupOrderInfo?.latitude,
        lng: groupOrderInfo?.longitude,
        service_type: val == "Delivery" ? 1 : 2,
        asap_or_advance: 1,
        date: "",
        time: "",
      };
      dispatch(initiateGroupOrder(model));
    }
    dispatch(headerTabSelected(val));
  };
  const handleListOutsideClick = () => {
    setSearchValue("");
    setList([]);
  };
  const handleListOutsideClickTwo = () => {
    // alert("cal1")
    setShowDateTime(false);
  };
  const handleDateToggle = (e) => {
    // alert("call")
    e.preventDefault();
    e.stopPropagation();
    // alert("cal2")
    setShowDateTime((p) => !p);
  };

  const { cartTotal, items = [] } = store.cart.cart;
  const { cart_items = [] } = store.gc.gc_cart;

  const renderCartIcon = () => {
    return (
      <div
        className="cart-wrapper"
        onClick={() =>
          props.disableCart ? () => null : setShowCart((prev) => !prev)
        }
      >
        <a href id="cart" className="cart-btn">
          <img src={shoppingBagImg} alt="bag icon" />
          <span className="cartcount">
            {groupToken ? (
              <>{groupOrderCartItems}</>
            ) : (
              <>{items.length ? items.length : cart_items.length}</>
            )}
          </span>
        </a>
      </div>
    );
  };
  let { id } = useParams();
  console.log("scroll =>", scroll)
  return (
    <>
      {renderRedirect()}

      {props.auth && !props.newHeaderImpl ? (

        <header className={`${scroll ? "sticky" : ""}`} style={scroll?{background:'white'}:{}}>
          
          <div className="container-fluid">
            
            <div className="landingPage-header-container">
              <div className="large-screen">
                <div className="d-flex align-items-center">
                  <div className="toggle-menu" ref={wrapperRefTwo}>
                        {/* <OutsideClickHandler
                          onOutsideClick={() => dispatch(setDraweState(false))}
                        > */}
                          <div
                            id="mySidenav"
                            className="sidenav"
                            style={{
                              left: store.auth.drawerState ? 0 : isMobileOnly?"-100vw":-275,
                              width: isMobileOnly?"100vw":"276px",
                              transition: "0.5s",
                            }}
                          >
                            <div
                              className="closebtn"
                              onClick={() => dispatch(setDraweState(false))}
                            >
                              &times;
                            </div>
                            <SideNav
                              setDraweState={(x) => dispatch(setDraweState(x))}
                            />
                          </div>
                        {/* </OutsideClickHandler> */}
                        
                      </div>
                  <div className="nav-logo">
                    <Link onClick={handleLandingNav} title="food dudes" to="#">
                      <img src={logoImg} alt="Food dude logo" width={"126"} />
                    </Link>
                  </div>
                  {!store.auth.token ? (
                    <div className="topnav ml-auto guestuser">
                      <NavLink
                        to="/signin"
                        title="Sign in"
                        className="btn-link"
                        activeClassName="btn btn-link btn-filled btn-hover"
                        // style={{ marginRight: "15px" }}
                        style={
                          isMobileOnly
                            ? {
                                marginRight: "5px",
                                backgroundColor: "transparent",
                                color: "var(--theme-black)",
                              }
                            : {
                                marginRight: "15px",
                              }
                        }
                      >
                        Sign In
                      </NavLink>
                      <NavLink
                        to="/signup"
                        title="Sign up"
                        className="btn-link"
                        activeClassName="btn btn-link btn-filled btn-hover"
                        style={
                          isMobileOnly
                            ? {
                                // backgroundColor: "transparent",
                                // color: "var(--theme-black)",
                              }
                            : props.signupCustom
                            ? {
                                padding: "9px 20px 12px 23px",
                                borderRadius: " 8px",
                                backgroundColor: "#fff",
                                color: "#1c1b1a",
                              }
                            : {}
                        }
                      >
                        Sign Up
                      </NavLink>
                      <Link className="d-none" to="/cart" title="my cart" >
                        <img src={cartImg} alt="my cart" />{" "}
                        <span className="cart-count">0</span>
                      </Link>
                    </div>
                  ) : (
                    <div class="topnav ml-auto signedIn">
                      {/* notification block */}
                      {needNotifications ? <NotificationDropdown /> : null}
                      {/* notification block */}
                      {/* <Link
                        data-toggle="modal"
                        // data-target="#profileModal"
                        to={
                          true
                            ? {
                                pathname: "/profile",
                                state: {
                                  background: isMobileOnly ? null : location,
                                },
                                data: { isProfile: true },
                              }
                            : { pathname: "/signin" }
                        }
                      >
                        <img src={profileImg} alt="profile" />
                      </Link> */}
                      <a href onClick={() => dispatch(setDraweState(true))} role="button">
                        <img src={profileImg} alt="profile"  />
                      </a>
                      <a
                        href
                        title="my cart"
                        onClick={() =>
                          isMobileOnly
                            ? history.push("/mobilecart")
                            : setShowCart((p) => !p)
                        }
                        style={(items.length>0 || cart_items.length > 0)?{}:{display:"none"}}
                      >
                        <img src={cartImg} alt="my cart" />
                        <span className="cart-count">{items.length || cart_items.length}</span>
                      </a>
                      <a
                        href
                        title="logout"
                        className="cur"
                        onClick={() => dispatch(resetAll())}
                        style={{marginLeft:"20px", display:"none"}}
                      >
                        <img src={logoutImg} alt="logout" />
                      </a>
                    </div>
                  )}
                </div>
                {!isMobileOnly ? (
                  <MobileCart groupCartHeaderCart showCart={showCart} fromLandingPage/>
                ) : null}
              </div>
            </div>
          </div>
        </header>
        
      ) : (
        <>
          {!isMobileOnly && !props.checkoutHeader ? (
            <header
              id="restaurants-listing"
              className={`${scroll ? "sticky" : ""}`}
            >
              <div className="background-bg">
                <div className="container-fluid">
                  <div className="header-wrapper">
                    <div className="left-header">
                      <div className="toggle-menu" ref={wrapperRefTwo}>
                        <OutsideClickHandler
                          onOutsideClick={() => dispatch(setDraweState(false))}
                        >
                          <div
                            id="mySidenav"
                            className="sidenav"
                            style={{
                              left: store.auth.drawerState ? 0 : -275,
                              transition: "0.5s",
                            }}
                          >
                            <div
                              className="closebtn"
                              onClick={() => dispatch(setDraweState(false))}
                            >
                              &times;
                            </div>
                            <SideNav
                              setDraweState={(x) => dispatch(setDraweState(x))}
                            />
                          </div>
                        </OutsideClickHandler>
                        <a href onClick={() => dispatch(setDraweState(true))}>
                          <img src={menuImg} alt="menu-icon" />
                        </a>
                      </div>
                      <div className="logo">
                        <Link to="#" onClick={handleLandingNav}>
                          <img src={logoImg} alt="Food dude logo" width="126" />
                        </Link>
                      </div>

                      {groupToken && !isItAdminLink ? null : (
                        <>
                          <div className="orderType_tabs">
                            <nav>
                              <div
                                className="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                              >
                                <a
                                  href
                                  className={`nav-link ${
                                    store.auth.headerTab == "Delivery"
                                      ? "active"
                                      : ""
                                  } ${
                                    props.disabledServiceType == "Delivery"
                                      ? "disabled-state"
                                      : ""
                                  }`}
                                  style={
                                    store.auth.headerTab == "Delivery"
                                      ? {
                                          cursor: "pointer",
                                          borderBottom: "5px solid #f06f00",
                                        }
                                      : { cursor: "pointer" }
                                  }
                                  onClick={() =>
                                    props.disabledServiceType == "Delivery"
                                      ? null
                                      : handleTabs("Delivery")
                                  }
                                >
                                  Delivery
                                </a>
                                <a
                                  href
                                  className={`nav-link ${
                                    props.disabledServiceType == "Pickup"
                                      ? "disabled-state"
                                      : ""
                                  }`}
                                  style={
                                    store.auth.headerTab == "Pickup"
                                      ? {
                                          cursor: "pointer",
                                          borderBottom: "5px solid #f06f00",
                                        }
                                      : { cursor: "pointer" }
                                  }
                                  onClick={() =>
                                    props.disabledServiceType == "Pickup"
                                      ? null
                                      : handleTabs("Pickup")
                                  }
                                >
                                  Pickup
                                </a>
                              </div>
                            </nav>
                          </div>
                        </>
                      )}

                      {groupToken && !isItAdminLink ? null : (
                        <>
                          <div className="timeandaddress d-none d-xl-flex">
                            {window.innerWidth > 1024 && (
                              <div className="picktime">
                                <OutsideClickHandler
                                  onOutsideClick={handleListOutsideClickTwo}
                                >
                                  <div className="btn-group">
                                    <a
                                      href
                                      role="button"
                                      className="btn dropdown-toggle"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                      id="date12"
                                      onClick={handleDateToggle}
                                    >
                                      {store.profile.deliveryDate
                                        ? `${moment(
                                            store.profile.deliveryDate
                                          ).format("ddd, MMM DD")} ${
                                            store.profile.timeInd
                                          }`
                                        : "ASAP"}
                                    </a>

                                    <div
                                      className="dropdown-menu"
                                      style={{
                                        display: showDateTime
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <DateAndTime
                                        invalidDates={invalidDates}
                                        handleDateToggle={
                                          handleListOutsideClickTwo
                                        }
                                        disabledAsapAdavance={
                                          props.disabledAsapAdavance
                                        }
                                      />
                                    </div>
                                  </div>
                                </OutsideClickHandler>
                              </div>
                            )}
                            <div>
                              <span>to</span>
                            </div>
                            <div className="picktime pickaddress">
                              <a
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                                style={
                                  entry_street_address
                                    ? {}
                                    : {
                                        color: "rgb(246, 153, 63)",
                                      }
                                }
                              >
                                {entry_street_name? entry_suburb?entry_suburb + ", "+entry_street_name:"" + entry_street_name:entry_street_address
                                  ? entry_street_address.split(",")[0]
                                  : "Please choose an address"}
                              </a>
                              <div
                                className="dropdown-menu"
                                style={showDateTime ? { display: "none" } : {}}
                              >
                                <ChangeAddressMobile
                                  handleChoosenAddress={handleChoosenAddress}
                                  setRecall={setRecall}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      )}

                      <div className="right-header d-none d-md-flex d-xl-none">
                        {window.innerWidth < 1025 && (
                          <div className="header-serach-restandcuisine position-relative">
                            <button
                              className="search"
                              onClick={() =>
                                handleSearch({
                                  target: { value: searchValue },
                                })
                              }
                            >
                              <img src={searchFooterImg} alt="search button" />
                            </button>
                            <input
                              type="search"
                              name=""
                              id=""
                              placeholder={
                                match?.isExact
                                  ? "Search food item"
                                  : "Search for restaurants/cuisines"
                              }
                              value={searchValue}
                              onChange={
                                match?.isExact ? handleMenuSearch : handleSearch
                              }
                              // onFocus={()=>setList([])}
                              // onBlur={()=>setList([])}
                            />

                            <div
                              className="picktime inner-header-search"
                              style={{
                                position: "absolute",
                                marginTop: "49px",
                                width: "100%",
                              }}
                            >
                              <div
                                className="dropdown-menu show"
                                ref={wrapperRef}
                                style={{
                                  display:
                                    searchValue.length > 0 ? "block" : "none",
                                }}
                              >
                                <OutsideClickHandler
                                  onOutsideClick={handleListOutsideClick}
                                >
                                  {groupToken || entry_street_address ? (
                                    <SearchList
                                      selectedRestData={selectedRestData}
                                      list={list}
                                      menuSearch={match?.isExact}
                                      goToMenu={goToMenu}
                                      showNotfound={
                                        searchValue.length > 0 &&
                                        list.length < 1
                                          ? true
                                          : false
                                      }
                                      searchValue={searchValue}
                                    />
                                  ) : null}
                                </OutsideClickHandler>
                              </div>
                            </div>
                          </div>
                        )}
                        {renderCartIcon()}
                        <MobileCart groupCartHeaderCart showCart={showCart} />

                        {/* <!--end shopping-cart --> */}
                      </div>
                    </div>

                    <div className="right-header d-none d-xl-flex">
                      {window.innerWidth > 1024 && (
                        <div className="header-serach-restandcuisine position-relative">
                          <button
                            className="search"
                            onClick={() =>
                              handleSearch({ target: { value: searchValue } })
                            }
                          >
                            <img src={searchFooterImg} alt="search button" />
                          </button>
                          <input
                            type="search"
                            name=""
                            id=""
                            placeholder={
                              match?.isExact
                                ? "Search food item"
                                : "Search for restaurants/cuisines"
                            }
                            value={searchValue}
                            onChange={
                              match?.isExact ? handleMenuSearch : handleSearch
                            }
                          />

                          <div
                            className="picktime inner-header-search"
                            style={{
                              position: "absolute",
                              marginTop: "49px",
                              width: "100%",
                            }}
                          >
                            <div
                              className="dropdown-menu show"
                              ref={wrapperRef}
                              style={{
                                display:
                                  searchValue.length > 0 ? "block" : "none",
                                width: "100%",
                              }}
                            >
                              <OutsideClickHandler
                                onOutsideClick={handleListOutsideClick}
                              >
                                {groupToken || entry_street_address ? (
                                  <SearchList
                                    selectedRestData={selectedRestData}
                                    list={list}
                                    menuSearch={match?.isExact}
                                    goToMenu={goToMenu}
                                    showNotfound={
                                      searchValue.length > 0 && list.length < 1
                                        ? true
                                        : false
                                    }
                                    searchValue={searchValue}
                                  />
                                ) : null}
                              </OutsideClickHandler>
                            </div>
                          </div>
                        </div>
                      )}
                      {renderCartIcon()}
                      <MobileCart groupCartHeaderCart showCart={showCart} />
                      {/* <!--end shopping-cart --> */}
                    </div>

                    {groupToken && !isItAdminLink ? null : (
                      <>
                        <div className="d-flex left-header d-xl-none pb-3 justify-content-start">
                          <div className="timeandaddress">
                            {window.innerWidth < 1025 && (
                              <div className="picktime">
                                <div className="btn-group">
                                  <a
                                    href
                                    type="button"
                                    className="btn dropdown-toggle"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                    onClick={handleDateToggle}
                                  >
                                    {store.profile.deliveryDate
                                      ? `${moment(
                                          store.profile.deliveryDate
                                        ).format("ddd, MMM DD")} ${
                                          store.profile.timeInd
                                        }`
                                      : "ASAP"}
                                  </a>

                                  <div
                                    className="dropdown-menu"
                                    style={{
                                      display: showDateTime ? "block" : "none",
                                    }}
                                  >
                                    <OutsideClickHandler
                                      onOutsideClick={handleListOutsideClickTwo}
                                    >
                                      <DateAndTime
                                        invalidDates={invalidDates}
                                        handleDateToggle={
                                          handleListOutsideClickTwo
                                        }
                                        disabledAsapAdavance={
                                          props.disabledAsapAdavance
                                        }
                                      />
                                    </OutsideClickHandler>
                                  </div>
                                </div>
                              </div>
                            )}
                            <div>
                              <span>to</span>
                            </div>
                            <div className="picktime pickaddress">
                              <a
                                href
                                className="btn dropdown-toggle"
                                data-toggle="dropdown"
                                aria-expanded="false"
                                style={
                                  entry_street_address
                                    ? {}
                                    : {
                                        color: "rgb(246, 153, 63)",
                                      }
                                }
                              >
                                {entry_street_name? entry_suburb?entry_suburb + ", "+ entry_street_name:"" + entry_street_name:entry_street_address
                                  ? entry_street_address
                                  : "Please choose an address"}
                              </a>
                              <div
                                className="dropdown-menu"
                                style={showDateTime ? { display: "none" } : {}}
                              >
                                <ChangeAddressMobile
                                  handleChoosenAddress={handleChoosenAddress}
                                  setRecall={setRecall}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </header>
          ) : (
            <>
              {(isMobileOnly && props.newHeaderImpl) || !isMobileOnly ? (
                <header
                  id="restaurants-listing"
                  // className={`d-none d-md-block ${scroll ? "sticky" : ""}`}
                  className={` ${scroll ? "sticky" : ""}`}
                >
                  <div className="background-bg">
                    <div className="container-fluid">
                      <div className="header-wrapper sp-7-header">
                        <div className="left-header">
                          <div
                            className="toggle-menu  d-none d-md-block"
                            ref={wrapperRefTwo}
                          >
                            <OutsideClickHandler
                              onOutsideClick={() =>
                                dispatch(setDraweState(false))
                              }
                            >
                              <div
                                id="mySidenav"
                                className="sidenav"
                                style={{
                                  left: store.auth.drawerState ? 0 : -275,
                                  transition: "0.5s",
                                }}
                              >
                                <div
                                  className="closebtn"
                                  onClick={() => dispatch(setDraweState(false))}
                                >
                                  &times;
                                </div>
                                <SideNav
                                  setDraweState={(x) =>
                                    dispatch(setDraweState(x))
                                  }
                                />
                              </div>
                            </OutsideClickHandler>
                            <a onClick={() => dispatch(setDraweState(true))}>
                              <img src={menuImg} alt="menu-icon" />
                            </a>
                          </div>
                          <div className="nav-logo logo py-0 py-md-3 ml-0 ml-md-4 pl-xl-3">
                            <Link onClick={handleLandingFromCheckout}>
                              <img
                                src={logoImg}
                                alt="Food dude logo"
                                width="126"
                                height="42"
                              />
                            </Link>
                          </div>
                        </div>
                        {(props.newHeaderImpl && !isMobileOnly)?<div class="right-header d-none d-xl-flex">
                          {renderCartIcon()}
                          <MobileCart groupCartHeaderCart showCart={showCart} />
                        </div>:null}
                      </div>
                    </div>
                  </div>
                </header>
              ) : null}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Header;
