import React, { useState, useEffect, Fragment, useRef } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import {
  setLoader,
  selectedMenuItem,
  setSelectedGroupOrderItem,
} from "redux-store";
import { useSelector, useDispatch } from "react-redux";
import NotFound from "../../components/NotFound";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment";
import { Link as Links, Element, scrollSpy } from "react-scroll";
import "./styles.css";
import useOnScreen from "./InViewPort";
import scrollIntoView from "scroll-into-view-if-needed";
import ItemDetail from "../../components/ItemDetail";
import menuImg from "assets/images/menu1.jpg";
import $ from "jquery";
import { useWindowResize } from "../../components/useWindowResize";
import { BASE_URL_IMAGE } from "../../service/ApiNames";
import { chekIfMenuOpen } from "../../service/common";
import { toast } from "react-toastify";

const MenuContent = (props) => {
  const isMobileOnly = useWindowResize();
  const store = useSelector((store) => store);
  const elemRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);

  const [objectMenus, setObjectMenus] = useState({});
  const [hideContent, setHideContent] = useState(true);

  const [disabled, setDisabled] = useState(true);
  const [checkedFilter, setCheckedFilter] = useState(new Set());
  const [showMenuTimings, setShowMenuTimings] = useState({});

  const { groupToken } = useParams();
  const { groupOrderInfo } = store.rest;

  useEffect(() => {
    let obj = {};
    props.menus &&
      props.menus.length &&
      props.menus.map((item, index) => {
        let val = chekIfMenuOpen(
          item.menu_opening_time,
          item.menu_closing_time,
          store.profile.deliveryDate ? store.profile.deliveryDate : null,
          store.profile.deliveryDate ? store.profile.deliveryTime : null
        );
        obj[item.menu_id] = val;
      });
    setShowMenuTimings(obj);
  }, [props.menus, store.profile.deliveryDate]);
  useEffect(() => {
    document.addEventListener("scroll", handleScroll);
    return () => document.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    handleScroll();
  }, [props.menus]);

  const [activateFirstElem, setActivateFirstElem] = useState(false);

  const handleScroll = () => {
    if (elemRef.current) {
      const pos = elemRef.current.getBoundingClientRect();
      if (
        pos && window.innerHeight < 1367
          ? pos.top > 120
          : pos.top > 67 && !activateFirstElem
      ) {
        setActivateFirstElem(true);
      }
    }
  };

  const handleTabs = (index) => {
    dispatch(setLoader(true));
    setCheckedFilter(new Set());
    setTimeout(() => {
      setActiveTab(index);
      dispatch(setLoader(false));
    }, 1000);
  };

  const test = useRef({});
  const [initialActive, setInitialActive] = useState(true);

  const [previos, setPrevios] = useState(false);

  const scrollToCategory = (id) => {
    if (initialActive) {
      setInitialActive(false);
    }

    if (activateFirstElem) {
      setActivateFirstElem(false);
    }
    // test.current[id] && test.current[id].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: "center" })
    if (test.current[id]) {
      scrollIntoView(test.current[id], {
        behavior: "smooth",
        block: "nearest",
        inline: "center",
        scrollMode: "if-needed",
      });
    }
  };

  let x = null;
  let xOne = null;
  if (props.menus.length) {
    console.log("props.menus[activeTab].submenus[0].menu_id",props )
    if(props && props.menus && props.menus[activeTab] &&  Array.isArray(props.menus[activeTab].submenus) && props.menus[activeTab].submenus.length && props.menus[activeTab].submenus[0] && props.menus[activeTab].submenus[0].menu_id){
      x = props.menus[activeTab].submenus[0].menu_id;
      xOne =
      props.menus[activeTab].submenus[
        props.menus[activeTab].submenus.length - 1
      ].menu_id;
    }
    
    
  }
  let y = false;
  if (Object.keys(test.current).length > 0 && x) {
    y = true;
  }
  const isVisible = useOnScreen(y ? test.current[x] : null);

  const isVisibleOne = useOnScreen(y ? test.current[xOne] : null);

  const handleMenuItem = (item) => {
    if (
      groupToken &&
      groupOrderInfo &&
      (groupOrderInfo?.is_deleted || groupOrderInfo?.status === 2)
    ) {
      if (groupOrderInfo?.is_deleted)
        toast.error("You are no longer part of this group order");
      if (groupOrderInfo?.status === 2)
        toast.error("This group order is canceled");
      return;
    }

    dispatch(
      setSelectedGroupOrderItem({
        ...item,
        products_price: Number(item.products_price),
        restaurant_name: props.restaurant_name,
        restaurant_id: props.restaurant_id,
        latlng: props.latlng,
        service_fee: props.service_fee,
        restaurant_delivery_fee: props.restaurant_delivery_fee,
        min_order: props.min_order,
        tax: props.tax,
        isEdit: false,
        selectedProduct: {},
        editIndex: null,
        address: props.address,
        postcode: props.postcode,
        allow_asap_advance_orders: props.allow_asap_advance_orders,
        service_fee_type: props.service_fee_type,
        allow_special_instructions: props.allow_special_instructions,
        minimum_pickup_order:props.minimum_pickup_order,
        minimum_deliver_order:props.minimum_deliver_order,
      })
    );
    dispatch(
      selectedMenuItem({
        ...item,
        products_price: Number(item.products_price),
        restaurant_name: props.restaurant_name,
        restaurant_id: props.restaurant_id,
        latlng: props.latlng,
        // fee:props.fee,
        service_fee: props.service_fee,
        restaurant_delivery_fee: props.restaurant_delivery_fee,
        min_order: props.min_order,
        tax: props.tax,
        isEdit: false,
        selectedProduct: {},
        editIndex: null,
        address: props.address,
        postcode: props.postcode,
        allow_asap_advance_orders: props.allow_asap_advance_orders,
        service_fee_type: props.service_fee_type,
        allow_special_instructions: props.allow_special_instructions,
        minimum_pickup_order:props.minimum_pickup_order,
        minimum_deliver_order:props.minimum_deliver_order,
      })
    );

    // if (isMobileOnly) {
    //   history.push("/menuitemmobile");
    // } else {
    dispatch({ type: "ITEM_MODAL", payload: { status: true, type: "" } });
    //   // setIsOpen({ status: true, type: "" })
    // }
  };

  return (
    <>
      {props.menus.length ? (
        <section className="menulisting-inner-tabs">
          <nav className={`${props.menus.length>1?"multiple-tabs":"single-tab"}`}>
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              {props.menus.map((item, index) => {
                if( props.menus.length < 2 ){
                  if(showMenuTimings[item.menu_id]){
                    return <a>
                      <p className="menu-tab-text">
                        Opens at{" "}
                        {moment(item.menu_opening_time, "hh mm ss").format(
                          "LT"
                        )}
                      </p>
                    </a>
                  }else{
                    return null
                  }
                }
                return (
                  <>
                  <a
                    key={item.menu_id}
                    className={`nav-item nav-link ${
                      activeTab == index ? "active-bg" : ""
                    } ${showMenuTimings[item.menu_id] ? "disabled-state" : ""}`}
                    id="nav-menu-tab"
                    onClick={() => handleTabs(index)}
                  >
                    {item.menu_title}
                    {showMenuTimings[item.menu_id] ? (
                      <p>
                        Opens at{" "}
                        {moment(item.menu_opening_time, "hh mm ss").format(
                          "LT"
                        )}
                      </p>
                    ) : null}
                  </a>
                  </>
                  
                );
              })}
            </div>
          </nav>
          <div className="menu-selection" style={{ zIndex: 1 }}>
            <div className="row">
              <div className="col-12">
                <section
                  className="sticky-nav-tabs"
                  ref={elemRef}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <ul
                    // id="myDIV"
                    id="scrollArea"
                    style={
                      isMobileOnly
                        ? {
                            display: "flex",
                            flexDirection: "row",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                            listStyleType: "none",
                            flexWrap: "nowrap",
                            justifyItems: "center",
                            width: "100%",
                            marginLeft: "0px",
                           
                          }
                        : {
                            display: "flex",
                            flexDirection: "row",
                            overflowY: "hidden",
                            whiteSpace: "nowrap",
                            listStyleType: "none",
                            paddingLeft: "20px",
                            flexWrap: "nowrap",
                            justifyItems: "center",
                            width: isMobileOnly
                              ? "100%"
                              : !isVisibleOne && !isVisible
                              ? "96.5%"
                              : isVisibleOne ||
                                !isVisible ||
                                !isVisibleOne ||
                                isVisible
                              ? "98.25%"
                              : "100%",
                            marginLeft: isVisible ? "-60px" : "-20px",

                            // marginRight:isVisibleOne?"32px":"0px"
                          }
                    }
                  >
                    {props &&
                      props.menus &&
                      props.menus.length &&
                      props.menus[activeTab] &&
                      props.menus[activeTab].submenus &&
                      props.menus[activeTab].submenus.map((category, index) => {
                        console.log("category",category)
                        const x =
                          category.products && category.products.length ? (
                            <li
                              key={category.menu_id}
                              className="menulisting__list"
                              style={{
                                display: "inline-block",
                              }}
                              // ref={test[category.menu_id]}
                              ref={(el) =>
                                (test.current[category.menu_id] = el)
                              }
                            >
                              <Links
                                activeClass="activeCategoryLink"
                                // className={(index==0&&activateFirstElem)?"initial":""}
                                className={
                                  props.menus[activeTab].submenus[0].menu_id ==
                                    category.menu_id && activateFirstElem
                                    ? "initial"
                                    : ""
                                }
                                to={category.menu_id.toString()}
                                spy={true}
                                smooth={true}
                                duration={500}
                                offset={
                                  isMobileOnly
                                    ? -128
                                    : window.innerWidth > 767 &&
                                      window.innerWidth < 1025
                                    ? -181
                                    : -140
                                }
                                onSetActive={() =>
                                  scrollToCategory(category.menu_id)
                                }
                                style={{ textDecoration: "none" }}
                                // spyThrottle={1000}
                              >
                                {category.menu_title}
                              </Links>
                            </li>
                          ) : null;
                        return x;
                      })}
                  </ul>

                  {!isVisibleOne && !isMobileOnly && (
                    <div className="right-arrow-btn-menu-list menu-list-page">
                      <div className="right-arrow-btn-menu-list-inner">
                        <button
                          type="button"
                          id="left"
                          className="slick-arrow slick-next"
                          onClick={() =>
                            $("#scrollArea").animate(
                              {
                                scrollLeft: $("#scrollArea").scrollLeft() + 250,
                              },
                              300
                            )
                          }
                        >
                          left
                        </button>
                      </div>
                    </div>
                  )}
                  {!isVisible && !isMobileOnly && (
                    <div className="left-arrow-btn-menu-list menu-list-page">
                      <div className="right-arrow-btn-menu-list-inner">
                        <button
                          type="button"
                          id="right"
                          className="slick-arrow slick-prev"
                          onClick={() =>
                            $("#scrollArea").animate(
                              {
                                scrollLeft: $("#scrollArea").scrollLeft() - 250,
                              },
                              300
                            )
                          }
                        >
                          right
                        </button>
                      </div>
                    </div>
                  )}
                </section>
              </div>
            </div>
          </div>
          <div className="menu__inDetails">
            <div
              className="tab-pane fade show active"
              id="nav-menu"
              role="tabpanel"
              aria-labelledby="nav-menu-tab"
            >
              <div className="tab-content-wrapper spa-main">
                {props &&
                  props.menus &&
                  props.menus.length &&
                  props.menus[activeTab] &&
                  props.menus[activeTab].submenus &&
                  props.menus[activeTab].submenus.map((item, index) => (
                    <section
                      className="sections"
                      id="tab-react"
                      key={"display" + item.menu_id.toString()}
                    >
                      {item.products && item.products.length && (
                        <Element
                          name={item.menu_id.toString()}
                          // className={item.menu_id}
                          // key={"display" + item.menu_id.toString()}
                        >
                          <section
                            className="spa-slide"
                            id={item.menu_id}
                            style={{
                              minHeight: "0px",
                              // marginBottom: "-195px",
                              // paddingTop: "145px",
                            }}
                          >
                            <h2>{item.menu_title}</h2>
                            <div
                              className="menu-grid"
                              style={{ marginBottom: "40px" }}
                            >
                              {item.products.map((newitem) => {
                                const {
                                  products_description,
                                  products_name,
                                  products_price,
                                  products_image,
                                  calculated_products_price,
                                } = newitem;
                                return (
                                  <a
                                    key={newitem.products_id}
                                    data-toggle={isMobileOnly ? null : "modal"}
                                    data-target={
                                      isMobileOnly ? null : null
                                      // : "#menu-item-detailsModal"
                                    }
                                    onClick={() => handleMenuItem(newitem)}
                                    type="button"
                                  >
                                    <div className="menu-item">
                                      <div className="left-content">
                                        <div className="menu-name">
                                          <h5>{products_name}</h5>
                                        </div>
                                        <div className="menu-desc">
                                          <span>{products_description}</span>
                                        </div>
                                        <div className="menu-price">
                                          <span>
                                            $
                                            {+products_price > 0
                                              ? Number.parseFloat(
                                                  Math.round(
                                                    (Number(products_price) +
                                                      Number.EPSILON) *
                                                      100
                                                  ) / 100
                                                ).toFixed(2)
                                              : parseFloat(
                                                  +calculated_products_price
                                                ).toFixed(2)}
                                          </span>
                                        </div>
                                      </div>
                                      {products_image ? (
                                        <div className="img-box">
                                          <img
                                            src={`${BASE_URL_IMAGE}storage/${products_image}`}
                                            alt="menu-image"
                                            className="img-fluid"
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                  </a>
                                );
                              })}
                            </div>
                          </section>
                        </Element>
                      )}
                    </section>
                  ))}
              </div>
            </div>
          </div>
        </section>
      ) : (
        <div style={{ minHeight: "600px" }}>
          {!hideContent && (
            <NotFound
              msgTitle="Menus not found"
              msgDescription="According to the specified resturant no available food items present
				for food orders at home."
            />
          )}
        </div>
      )}
    </>
  );
};

export default MenuContent;
