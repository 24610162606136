import React, { useEffect, useState } from "react";
import { useHistory, Redirect, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  selectedAddress,
  selectedMenuItem,
  setCartItems,
  setPrevAddress,
  setSelectedGroupOrderItem,
} from "redux-store";
import { toast } from "react-toastify";
import ItemAttributes from "../components/ItemAttributes";
import deleteImg from "assets/images/delete-icon-sp3.svg";
import removeCartImg from "assets/images/remove-cart-icon.svg";
import backArrowImg from "assets/images/back-arrow-mobile.svg";
import { useWindowResize } from "./../components/useWindowResize";
import GroupOrderCart from "./GroupOrderCart";
import GcCart from "components/GcCart";
import ItemDetail from "components/ItemDetail";

const itemTotalCalculator = (item, customers_basket_quantity) => {
  const { products_price, optionValuesNew = [] } = item;
  let itemTotal = products_price * customers_basket_quantity;

  optionValuesNew.map((item) => {
    itemTotal =
      itemTotal + item.options_values_price * customers_basket_quantity;
  });
  return itemTotal;
};

const NormalOrderCart = (props) => {
  const isMobileOnly = useWindowResize();

  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((store) => store);
  // const [restStatus, setRestStatus] = useState(true)
  const {
    items = [],
    restaurant_id,
    restaurant_name,
    cartTotal,
    min_order,
    minimum_deliver_order,
		minimum_pickup_order,
    
  } = store.cart.cart;
  let restStatus = 1;

  if (store.cart.cart.restaurant_id) {
    restStatus =
      store.rest.selectedResturantData[store.cart.cart.restaurant_id]
        ?.rest_status;
  }
  // const {
  //   rest_status
  // } = store.rest.selectedResturantData[store.cart.cart.restaurant_id]
  const [redirect, setRedirect] = useState(false);
  useEffect(() => {
    if (isMobileOnly && items.length < 1) {
      // history.goBack()
      dispatch({ type: "ROUTEPLACE", payload: false });
      setRedirect(true);
    }

    // if(isChanged!=store.cart.cart.restaurant_id){
    //   setIsChanged(store.cart.cart.restaurant_id)
    // }
  }, [store.cart.cart]);

  // useEffect(() => {

  // }, [store.rest.selectedResturantData]);

  const handleQuantity = (item, index, type) => {
    const cart = { ...store.cart.cart };
    switch (type) {
      case "increment":
        const copy = {
          ...item,
          customers_basket_quantity: item.customers_basket_quantity + 1,
          total: itemTotalCalculator(item, item.customers_basket_quantity + 1),
        };

        cart.items.splice(index, 1, copy);
        break;
      case "decrement":
        const copyOne = {
          ...item,
          customers_basket_quantity: item.customers_basket_quantity - 1,
          total: itemTotalCalculator(item, item.customers_basket_quantity - 1),
        };

        cart.items.splice(index, 1, copyOne);
        break;
      case "delete":
        cart.items.splice(index, 1);
        break;
    }

    if (cart.items.length > 0) {
      dispatch(setCartItems(cart));
    } else {
      dispatch(setCartItems({}));
    }
  };
  const handleEdit = (item, index) => {

		dispatch(
			setSelectedGroupOrderItem({
				...item,
				...store.rest.itemsDetail[item.products_id],
				products_price: Number(item.products_price),
				restaurant_name: restaurant_name,
				restaurant_id: restaurant_id,
				isEdit: true,
				selectedProduct: item,
			}),
		);

		dispatch(
			selectedMenuItem({
				...item,
				...store.rest.itemsDetail[item.products_id],
				products_price: Number(item.products_price),
				restaurant_name: restaurant_name,
				restaurant_id: restaurant_id,
				isEdit: true,
				selectedProduct: item,
				editIndex: index,
			}),
		);

		// isMobileOnly
		// 	? history.push("/menuitemmobile")
		// :
		dispatch({ type: "ITEM_MODAL", payload: { status: true, type: "" } });
	};


  useEffect(() => {
    return () => {
      closeItemModal();
    };
  }, []);

  const closeItemModal = () => { 
    if(store.tab.modalIsOpen.status){
      dispatch({ type: "ITEM_MODAL", payload: { status: false, type: "" } });
    }
  };

  const handleDelete = (item, index) => {
    const cart = { ...store.cart.cart };
    cart.items.splice(index, 1);
    if (cart.items.length) {
      dispatch(setCartItems(cart));
    } else {
      dispatch(setCartItems({}));
    }

    // toast.success("Successfully removed from your order");
  };

  const renderItems = (item, index) => {
    const {
      optionValuesNew = [],
      products_id,
      products_name,
      total,
      specialInstruction,
      customers_basket_quantity,
    } = item;
    return (
      <div
        className={`order-details-card ${
          store.cart.disabledProducts[products_id] || restStatus != 1
            ? "disabled-state"
            : ""
        }`}
        key={products_id + index}
      >
        <div className="d-flex justify-content-between">
          <div className="rightside" onClick={() => handleEdit(item, index)}>
            <span className="item-count">{customers_basket_quantity}x</span>
            <div className="order-item-details">
              <h5>{products_name}</h5>
            </div>
          </div>
          <div className="order-price">
            <span>${parseFloat(total).toFixed(2)}</span>
            <button
              className="btn delete-btn"
              // onClick={() => handleDelete(item, index)}
              onClick={() =>
                store.cart.disabledProducts[products_id] || restStatus != 1
                  ? null
                  : handleQuantity(item, index, "delete")
              }
            >
              <img className="img-fluid" src={deleteImg} alt="" />
            </button>
          </div>
        </div>
        <div className="order-item-details">
          <div>
            {optionValuesNew.map((value, index) => (
              <span className={"comma"}>
                {value.options_values_id
                  ? value.products_options_values_name
                  : null}
              </span>
            ))}
          </div>
          {optionValuesNew.map((value, index) =>
            !value.options_values_id ? (
              <p>{value.products_options_values_text}</p>
            ) : null
          )}

          <p>{specialInstruction}</p>
        </div>
      </div>
    );
  };

  const renderItemsDesktop = (item, index) => {
    const {
      optionValuesNew = [],
      products_id,
      products_name,
      total,
      specialInstruction,
      customers_basket_quantity,
    } = item;
    return (
      <li
        className={`clearfix item ${
          store.cart.disabledProducts[products_id] || restStatus != 1
            ? "disabled-state"
            : ""
        }`}
        key={products_id + index + store?.cart?.cart?.restaurant_id}
      >
        <div>
          <div className="d-flex align-items-start">
            <div
              className="item-quantity"
              onClick={() => handleEdit(item, index)}
            >
              <span className="quantity-count" id="quantity-count">
                {customers_basket_quantity}x
              </span>
            </div>

            <div
              className="cart-item"
              style={{ cursor: "pointer" }}
              onClick={() => handleEdit(item, index)}
            >
              <p className="item-name">{products_name}</p>
              <p className="item-price">${parseFloat(total).toFixed(2)}</p>
            </div>

            <div
              className="remove-item"
              onClick={() =>
                store.cart.disabledProducts[products_id] || restStatus != 1
                  ? null
                  : handleQuantity(item, index, "delete")
              }
            >
              <button>
                <span className="icon">
                  <img src={removeCartImg} alt="remove" />
                </span>
              </button>
            </div>
          </div>

          <ItemAttributes
            optionValuesNew={optionValuesNew}
            specialInstruction={specialInstruction}
          />
        </div>
      </li>
    );
  };
  const handleNav = () => {

    if (Object.keys(store.cart.disabledProducts).length || restStatus != 1) {

    } else {
      if (cartTotal < (store.auth.headerTab == "Delivery" ?minimum_deliver_order:minimum_pickup_order)) {
        toast.error(
          `Sorry! minimum order value is $${store.auth.headerTab == "Delivery" ?minimum_deliver_order:minimum_pickup_order}`
        );
      } else {
        if(props.fromLandingPage){
          dispatch(selectedAddress({...store.profile.prevAddress}))
          dispatch(setPrevAddress({}))
        }
        
        history.push("/webcheckout");
      }
    }
  };
  if (isMobileOnly) {
    return (
      <>
        {redirect ? (
          <Redirect to="/" />
        ) : (
          <>
            {store.tab.modalIsOpen.status ? <ItemDetail /> : null}

            <main className="mobile-inner-screen">
              <div className="payment__page__wrapper">
                <div className="go-back-wrapper">
                  <a className="go-back-btn" onClick={() => history.goBack()}>
                    <img src={backArrowImg} alt="go back" />
                  </a>
                  <h2>{restaurant_name}</h2>
                </div>
                <div className="main-wrapper">
                  <section className="mobile-inner-section">
                    <div className="main">
                      <div className="container">
                        <div className="order-details-cards-wrapper normal-checkout-page">
                          <div className="row">
                            <div className="col-12">
                              {items.map(renderItems)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="meanu-listing-mobile-footer checkout-footer">
                    <div className="container">
                      <div className="bg">
                        <div className="subtotal-wrapper">
                          <span>Subtotal</span>
                          <span>${parseFloat(cartTotal).toFixed(2)}</span>
                        </div>
                        <div
                          className={`modal-bottom-actions ${
                            Object.keys(store.cart.disabledProducts).length ||
                            restStatus != 1
                              ? "disabled-state"
                              : ""
                          }`}
                        >
                          <div
                            className="place-order-btn-wrapper"
                            onClick={handleNav}
                          >
                            <button type="button" className="btn btn-filled">
                              Checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </main>
          </>
        )}
      </>
    );
  }
  return (
    <div
      className="shopping-cart shopping-cart-web"
      style={{ display: props.showCart ? "inline-flex" : "none" }}
    >
      {items.length ? (
        <>
          <div
            className={`shopping-cart-header ${
              Object.keys(store.cart.disabledProducts).length || restStatus != 1
                ? "disabled-state"
                : " "
            }`}
          >
            <h4>Your Order</h4>
            <div className="restra-container">
              <label for="restraName">From</label>
              <p className="restraName">{restaurant_name}</p>
            </div>
          </div>
          {/* <!--end shopping-cart-header --> */}
          <ul
            className="shopping-cart-items"
            key={store?.cart?.cart?.restaurant_id}
          >
            {items.map(renderItemsDesktop)}
          </ul>
          <a
            className={`checked-btn text-center ${
              Object.keys(store.cart.disabledProducts).length || restStatus != 1
                ? "disabled-state"
                : " "
            }`}
            onClick={handleNav}
            role="button"
          >
            <span className="d-block w-100">Checkout</span>
            {/* <span className="totalAmount">${parseFloat(cartTotal).toFixed(2)}</span> */}
          </a>
        </>
      ) : (
        <div className="no-items-in-cart">
          <h4>No items in your cart</h4>
        </div>
      )}
    </div>
  );
};

const MobileCart = (props) => {
  const { groupToken } = useParams();
  const dispatch = useDispatch()
  const store = useSelector((store) => store);
  const { groupOrderInfo } = store.rest;
  const isMobileOnly = useWindowResize();
  const history = useHistory();
  let name = "";
  if (store.gc.gc_cart.restaurant_name) {
    let x = store.gc.gc_cart.restaurant_name.split("/");
    name = x[x.length - 1];
  }
  const handleNavGc = () => {
    switch (store.gc.gc_cart.restaurant_name) {
      case "/redeem-points-/EGIFT_CARD":
        history.push("/webcheckout_giftcards_egift");
        
        break;
      case "/redeem-points-/GIFT_CARD":
        history.push("/webcheckout_giftcards_gift");
       
        break;
      case "/buy-gift-card-/GIFT_CARD":
        history.push("/webcheckout_giftcards_buy_gift");
       
        break;
      case "/buy-gift-card-/EGIFT_CARD":
        history.push("/webcheckout_giftcards_buy_egift");
        break;
    }
    if(props.fromLandingPage){
      dispatch(selectedAddress({...store.profile.prevAddress}))
      dispatch(setPrevAddress({}))
    }
  };
  return (
    <>
      {groupToken && groupOrderInfo && groupOrderInfo?.id ? (
        <GroupOrderCart {...props} itemTotalCounter={itemTotalCalculator} />
      ) : (
        <>
          {/* <NormalOrderCart {...props} /> */}
          {store.cart.cart.restaurant_id || !store.gc.gc_cart.restaurant_id ? (
            <NormalOrderCart {...props} />
          ) : (
            <>
              {!isMobileOnly ? (
                <div
                  className="shopping-cart shopping-cart-web"
                  style={{
                    display: props.showCart ? "inline-flex" : "none",
                  }}
                >
                  {name ? (
                    <div className={`shopping-cart-header`}>
                      <h4>Your Order</h4>
                      <div className="restra-container">
                        <label for="restraName">From</label>
                        <p className="restraName">
                          Gift Card / E-Gift Card
                          {/* {name} */}
                        </p>
                      </div>
                    </div>
                  ) : null}
                  <div className="gift-card-cart-dollars">
                    <GcCart
                      cartItems={
                        store.gc.gc_cart.cart_items
                          ? store.gc.gc_cart.cart_items
                          : []
                      }
                      cartTotal={store.gc.gc_cart.cartTotal}
                      isRewards={
                        store.gc.gc_cart.restaurant_name &&
                        store.gc.gc_cart.restaurant_name.includes(
                          "redeem-points"
                        )
                          ? true
                          : false
                      }
                    />
                  </div>
                  {name && (
                    <a
                      className={`checked-btn text-center `}
                      onClick={handleNavGc}
                      role="button"
                    >
                      <span className="d-block w-100">Checkout</span>
                    </a>
                  )}
                </div>
              ) : (
                <main className="mobile-inner-screen">
                  <div className="payment__page__wrapper">
                    <div className="go-back-wrapper">
                      <a
                        className="go-back-btn"
                        onClick={() => history.goBack()}
                      >
                        <img src={backArrowImg} alt="go back" />
                      </a>
                      <h2>{name}</h2>
                    </div>

                    <div className="main-wrapper">
                      <section className="mobile-inner-section">
                        <div className="main">
                          <div className="container">
                            <div className="order-details-cards-wrapper normal-checkout-page">
                              <div className="row">
                                <div className="col-12">
                                  <GcCart
                                    cartItems={
                                      store.gc.gc_cart.cart_items
                                        ? store.gc.gc_cart.cart_items
                                        : []
                                    }
                                    cartTotal={store.gc.gc_cart.cartTotal}
                                    isRewards={
                                      store.gc.gc_cart.restaurant_name &&
                                      store.gc.gc_cart.restaurant_name.includes(
                                        "redeem-points"
                                      )
                                        ? true
                                        : false
                                    }
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </main>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default MobileCart;
