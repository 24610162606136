import React, { useEffect, useState } from "react";
import MobileView from "./MobileView";
import DesktopView from "./DesktopView";
import { useSelector, useDispatch } from "react-redux";
import {
  getItemAddon,
  groupOrderAddToCart,
  setCartItems,
  setCoupan,
  setMenuAddon,
  setSelectedGroupOrderItem,
  setDisabledProducts,
  setGcardCartItems,
  selectedMenuItem,
} from "redux-store";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import {
  useHistory,
  useLocation,
  useParams,
  withRouter,
  RouteComponentProps,
} from "react-router-dom";
import alertCloseImg from "assets/images/alert-close-icon.svg";
import { useWindowResize } from "../useWindowResize";
import moment from "moment";

const ItemDetail = (props) => {
  const isMobileOnly = useWindowResize();
  const history = useHistory();
  const dispatch = useDispatch();
  const store = useSelector((s) => s);
  const [radio, setRadio] = useState({});
  const [dropDown, setDropDown] = useState(null);
  const [checkBox, setCheckBox] = useState([]);
  const [text, setText] = useState(null);
  const [specialInstruction, setSpecialInstruction] = useState(null);
  const [customers_basket_quantity, setBasketQuantity] = useState(1);
  const [quantityLoader, setQuantityLoader] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [cartItem, setCartItem] = useState(null);
  const [total, setTotal] = useState(null);
  const [optionValuesNew, setOptionValuesNew] = useState([]);
  const location = useLocation();

  const { groupToken } = useParams();
  const { selectedGroupOrderItem, groupOrderInfo } = store.rest;

  const [limitExceedMessage, setLimitExceedMessage] = useState(null);
  const [isItemNotAvailable, setIsItemNotAvailable] = useState(false);
  const [disableCartButton, setDisableCartButton] = useState(false);

  function clearData() {
    setRadio({});
    setDropDown(null);
    setCheckBox([]);
    setText([]);
    setSpecialInstruction(null);
    setBasketQuantity(1);
    setQuantityLoader(false);
    setEditIndex(null);
    setCartItem(null);
    setTotal(null);
    setOptionValuesNew([]);
  }

  useEffect(() => {
    // dispatch(setCartItems({}))
    if (store.rest.itemsDetail[store.rest.selectedMenuItem.products_id]) {
      let data =
        store.rest.itemsDetail[store.rest.selectedMenuItem.products_id];

      const {
        products_id,
        products_price,
        restaurant_id,
        restaurant_name,
        tax,
        isEdit,
      } = data;

      const optionValues = [];
      if (radio) {
        Object.keys(radio).map((item) => {
          optionValues.push(radio[item]);
        });
      }
      if (dropDown) {
        optionValues.push(dropDown);
      }
      if (checkBox.length) {
        checkBox.map((item) => {
          optionValues.push(item);
        });
      }
      if (text) {
        optionValues.push(text);
      }

      let itemTotal =
        products_price *
        (customers_basket_quantity ? customers_basket_quantity : 1);
      if (optionValues.length) {
        optionValues.map((item, index) => {
          itemTotal =
            itemTotal +
            item.options_values_price *
              (customers_basket_quantity ? customers_basket_quantity : 1);
        });
      }


      setTotal(itemTotal);
      setOptionValuesNew(optionValues);
    }
  }, [radio, dropDown, checkBox, customers_basket_quantity, text]);

  useEffect(() => {
    if (store.rest.selectedMenuItem.isEdit) {
      // setBasketQuantity(store.rest.selectedMenuItem.customers_basket_quantity)
    } else {
      setBasketQuantity(1);
    }
  }, [store.rest.selectedMenuItem]);

  const handleQuantity = (value) => {
    setQuantityLoader(true);
    value
      ? setBasketQuantity(customers_basket_quantity + 1)
      : setBasketQuantity(customers_basket_quantity - 1);
    setQuantityLoader(false);
  };

  const onChangeInput = (e) => {
    if (e.target.value) {
      let val = e.target.value.replace(/ /g, "");
      if (!Number(val)) {
        return;
      }
      if (Number(val) > 9999) {
        toast.error("Quantity greater than 9999 not allowed ");
        return;
      }
      setBasketQuantity(Number(val));
    } else {
      setBasketQuantity(e.target.value);
    }
  };

  // const isSameUser = (a, b) => a.value == b.value && a.display == b.display;
  const isSameUser = (a, b) =>
    a.options_values_id == b.options_values_id && a.options_id == b.options_id;
  function getAllIndexes(arr, val) {
    var indexes = [],
      i = -1;
    while ((i = arr.indexOf(val, i + 1)) != -1) {
      indexes.push(i);
    }
    return indexes;
  }

  const handleAddToCart = () => {
    if (groupToken) {
      addItemToGroupOrderCart();
    } else {
      addItemToNormalCart();
    }
  };

  useEffect(() => {
    if (groupToken) {
      groupOrderInitiatorHandler();
    }
  }, [total]);

  useEffect(() => {
    return () => {
      closeItemModal();
    };
  }, []);

  const groupOrderInitiatorHandler = () => {
    setLimitExceedMessage(null);
    setIsItemNotAvailable(false);
    let userAmountLimit = +groupOrderInfo?.amount_limit?.toFixed(2);

    let cart = store.groupCart.cart?.selectedUserCart
      ? store.groupCart.cart?.selectedUserCart
      : null;

    let priceTillNow =
      cart?.items && cart?.items?.length
        ? cart.items?.map((it) => +it.final_price).reduce((pt, nt) => +pt + +nt)
        : 0;

    let totalCartTillNow = 0;

    if (store.rest.selectedMenuItem.isEdit) {
      let product_id = +store.rest.selectedMenuItem.products_id;
      let selectedItem = null;
      if (cart?.items && cart?.items.length) {
        selectedItem = cart.items?.find((it) => +it.products_id === product_id);
      }
      totalCartTillNow = +priceTillNow - +selectedItem.final_price + +total;
    } else {
      totalCartTillNow = +priceTillNow + +total;
    }

    if (userAmountLimit !== 0) {
      if (totalCartTillNow > userAmountLimit) {
        setLimitExceedMessage(
          `You've exceeded the $${userAmountLimit} limit that is set on this group order! Please remove and add other items.`
        );
      }
    }

    if (
      selectedGroupOrderItem?.is_product_available !== undefined &&
      !selectedGroupOrderItem?.is_product_available
    ) {
      setIsItemNotAvailable(true);
    }
  };

  const addItemToGroupOrderCart = () => {
    if (!navigator.onLine) {
      toast.error(
        store.rest.selectedMenuItem.isEdit
          ? "Please go online to update order"
          : "Please go online to add items in your cart"
      );
      return;
    }

    let selectedItem = selectedGroupOrderItem?.selectedProduct;

    let attributes = optionValuesNew.map((it) => ({
      options_id: it.options_id,
      options_values_id: it.options_values_id,
      options_values_price: it.options_values_price,
      products_options_values_text: it.products_options_values_text,
    }));

    let model = {
      member_token: groupToken,
      menu_id: selectedGroupOrderItem.menu_id || +selectedItem?.menu_id,
      products_id:
        selectedGroupOrderItem.products_id || +selectedItem?.products_id,
      customers_basket_quantity: customers_basket_quantity,
      attributes: JSON.stringify(attributes),
    };
    // if (attributes && attributes?.length) {
    //   model["attributes"] = JSON.stringify(attributes);
    // }
    if (selectedItem && selectedItem?.customers_basket_id) {
      model["customers_basket_id"] = selectedItem?.customers_basket_id;
    }

    dispatch(
      groupOrderAddToCart(
        model,
        () => {
          toast.success(
            !store.rest.selectedMenuItem.isEdit
              ? "Successfully added to your order"
              : "Your order updated successfully"
          );
          closeItemModal();
        },
        (data) => {
          toast.error(data);
        }
      )
    );
  };

  const closeItemModal = () => {
    dispatch({ type: "ITEM_MODAL", payload: { status: false, type: "" } });
  };

  const addItemToNormalCart = () => {
    if (!navigator.onLine) {
      toast.error(
        store.rest.selectedMenuItem.isEdit
          ? "Please go online to update order"
          : "Please go online to add items in your cart"
      );
      return;
    }

    const cart = { ...store.cart.cart };
    if (store.rest.selectedMenuItem.isEdit) {
      cart.items.splice(editIndex, 1);
    }

    const data =
      store.rest.itemsDetail[store.rest.selectedMenuItem.products_id];
    const {
      products_id,
      products_name,
      products_price,
      restaurant_id,
      restaurant_name,
      latlng,
      service_fee,
      restaurant_delivery_fee,
      min_order,
      tax,
      address,
      postcode,
      service_type,
      allow_asap_advance_orders,
      menu_id,
    } = data;

    if (Object.keys(cart).length && cart.restaurant_id == restaurant_id) {
      const items = [...cart.items];
      const index = items
        .map(function (e) {
          return e.products_id;
        })
        .indexOf(products_id);
      const allindexes = items.map(function (e) {
        return e.products_id;
      });
      const a = getAllIndexes(allindexes, products_id);
      if (index != -1) {
        // A comparer used to determine if two entries are equal.
        // Get items that only occur in the left array,
        // using the compareFunction to determine equality.

        const onlyInLeft = (left, right, compareFunction) =>
          left.filter(
            (leftValue) =>
              !right.some((rightValue) =>
                compareFunction(leftValue, rightValue)
              )
          );

        // let diffObj = {}
        let newInd = null;
        a.map((item) => {
          const onlyInAT = onlyInLeft(
            optionValuesNew,
            items[item].optionValuesNew,
            isSameUser
          );
          const onlyInBT = onlyInLeft(
            items[item].optionValuesNew,
            optionValuesNew,
            isSameUser
          );
          // diffObj = {...diffObj, [item]:[...onlyInAT, ...onlyInBT]}
          if ([...onlyInAT, ...onlyInBT].length < 1) {
            newInd = item;
          }
        });

        const onlyInA = onlyInLeft(
          optionValuesNew,
          items[index].optionValuesNew,
          isSameUser
        );
        const onlyInB = onlyInLeft(
          items[index].optionValuesNew,
          optionValuesNew,
          isSameUser
        );

        const result = [...onlyInA, ...onlyInB];
        if (newInd === null) {
          items.push({
            menu_id,
            products_id,
            products_price,
            products_name,
            customers_basket_quantity,
            optionValuesNew,
            total: total,
            specialInstruction: specialInstruction,
          });
          dispatch(
            setCartItems({
              ...cart,
              items: items,
              date:moment().format()
            })
          );
          toast.success(
            !store.rest.selectedMenuItem.isEdit
              ? "Successfully added to your order"
              : "Your order updated successfully"
          );
        } else {
          let updatedItem = { ...items[newInd] };
          let customers_basket_quantity_two =
            customers_basket_quantity + updatedItem.customers_basket_quantity;
          // let itemTotalTwo = products_price*customers_basket_quantity
          let updatedItemN = {
            menu_id,
            products_id,
            products_price,
            products_name,
            customers_basket_quantity: customers_basket_quantity_two,
            optionValuesNew,
            total: updatedItem.total + total,
            specialInstruction: specialInstruction,
          };
          items[newInd] = updatedItemN;
          dispatch(
            setCartItems({
              ...cart,
              items: items,
              date:moment().format()
            })
          );
          toast.success(
            !store.rest.selectedMenuItem.isEdit
              ? "Successfully added to your order"
              : "Your order updated successfully"
          );
        }
      } else {
        items.push({
          menu_id,
          products_id,
          products_price,
          products_name,
          customers_basket_quantity,
          optionValuesNew,
          total: total,
          specialInstruction: specialInstruction,
        });
        dispatch(
          setCartItems({
            ...cart,
            items: items,
            date:moment().format()
          })
        );
        toast.success(
          !store.rest.selectedMenuItem.isEdit
            ? "Successfully added to your order"
            : "Your order updated successfully"
        );
      }
    } else if (store.gc.gc_cart.restaurant_name) {
      alertBox(
        {
          restaurant_id: restaurant_id,
          restaurant_name: restaurant_name,
          latlng: latlng,
          address: address,
          service_fee,
          min_order,
          restaurant_delivery_fee,
          tax,
          postcode,
          service_type,
          allow_asap_advance_orders,
          items: [
            {
              menu_id,
              products_id,
              products_price,
              products_name,
              customers_basket_quantity,
              optionValuesNew,
              total: total,
              specialInstruction: specialInstruction,
            },
          ],
        },
        "fromGcCart"
      );
    } else {
      if (cart.restaurant_id) {
        alertBox({
          restaurant_id: restaurant_id,
          restaurant_name: restaurant_name,
          latlng: latlng,
          address: address,
          service_fee,
          min_order,
          restaurant_delivery_fee,
          tax,
          postcode,
          service_type,
          allow_asap_advance_orders,
          items: [
            {
              menu_id,
              products_id,
              products_price,
              products_name,
              customers_basket_quantity,
              optionValuesNew,
              total: total,
              specialInstruction: specialInstruction,
            },
          ],
        });
      } else {

        dispatch(
          setCartItems({
            restaurant_id: restaurant_id,
            restaurant_name: restaurant_name,
            latlng: latlng,
            service_fee,
            restaurant_delivery_fee,
            tax,
            min_order,
            address,
            postcode,
            allow_asap_advance_orders,
            service_type,
            items: [
              {
                menu_id,
                products_id,
                products_price,
                products_name,
                customers_basket_quantity,
                optionValuesNew,
                total: total,
                specialInstruction: specialInstruction,
              },
            ],
            date: moment().format()
          })
        );

        toast.success(
          !store.rest.selectedMenuItem.isEdit
            ? "Successfully added to your order"
            : "Your order updated successfully"
        );
      }
    }

    // isMobileOnly
    //   ? location?.state?.menuSearch
    //     ? history.go(-2)
    //     : history.goBack()
    //   :
    closeItemModal();
  };

  const [alertConfimation, setAlertConfirmation] = useState({
    status: false,
    data: null,
  });

  const alertBox = (data, isGcCart) => {
    // setAlertConfirmation({status:true, data:data})
    dispatch(setCoupan({}));
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className="custom-ui">
            <button className="close-alert" onClick={onClose}>
              <img src={alertCloseImg} alt="x" />
            </button>
            <div className="inner__content">
              <h1>Warning</h1>
              <p>
                {isGcCart
                  ? "Food items and Gift Card/e-Gift Card can not be purchased together. Do you want to discard the selection and add different items?"
                  : "Your cart contains items from another restaurant. Do you want to discard the selection and add items from this Restaurant?"}
              </p>
              <div className="action__btns__wrapper">
                <button onClick={onClose}>Cancel</button>
                <button
                  onClick={() => {
                    // this.handleClickDelete();
                    
                    if (isGcCart) {
                      dispatch(setGcardCartItems({}));
                    
                      dispatch(setCartItems({...data, date:moment().format()}));
                    }

                    onClose();
                    toast.success(
                      !store.rest.selectedMenuItem.isEdit
                        ? "Successfully added to your order"
                        : "Your order updated successfully"
                    );
                  }}
                >
                  Yes
                </button>
              </div>
            </div>
          </div>
        );
      },
    });
    dispatch(setDisabledProducts({}));
  };

  useEffect(() => {
    let data = { ...store.rest.selectedMenuItem };

    console.log("store.rest.selectedMenuItem", store.rest.selectedMenuItem);
    let isGroupOrderItemAvailable = false;
    if (data.isEdit && groupToken) {
      isGroupOrderItemAvailable = true;
    }

    dispatch(
      getItemAddon(
        data,
        (res) => {
          if (
            res?.is_product_available !== undefined &&
            !res?.is_product_available
          ) {
            setIsItemNotAvailable(true);
          }
          if (!store.rest.selectedMenuItem.isEdit) {
            setTotal(
              Number(
                +data.products_price > 0
                  ? data.products_price
                  : +data.calculated_products_price
              )
            );
          }
        },
        () => closeItemModal()
      )
    );
    if (
      store.rest.selectedMenuItem &&
      !store.rest.itemsDetail[store.rest.selectedMenuItem.products_id]
    ) {
    } else {
      if (store.rest.selectedMenuItem.isEdit) {
        setEditIndex(store.rest.selectedMenuItem.editIndex);


        setTotal(
          store.rest.selectedMenuItem.selectedProduct.total
            ? store.rest.selectedMenuItem.selectedProduct.total
            : store.rest.selectedMenuItem.selectedProduct.final_price
        );
        setBasketQuantity(
          store.rest.selectedMenuItem.selectedProduct.customers_basket_quantity
        );
        setSpecialInstruction(
          store.rest.selectedMenuItem.selectedProduct.specialInstruction
        );
        let syncRadio = {};
        let syncChecbox = [];
        let newEntries =
          store.rest.selectedMenuItem.selectedProduct?.optionValuesNew;

        if (!newEntries) {
          console.log(
            "xn xc newEntries",
            store.rest.selectedMenuItem.selectedProduct?.options
          );
          newEntries = [];
          if (
            Array.isArray(store.rest.selectedMenuItem.selectedProduct?.options)
          ) {
            store.rest.selectedMenuItem.selectedProduct?.options.map((item) => {
              if (Array.isArray(item.optionValues)) {
                item.optionValues.map((it) => {
                  newEntries.push(it);
                });
              }
            });
          }

          // newEntries = store.rest.selectedMenuItem.selectedProduct?.options;

          setBasketQuantity(
            store.rest.selectedMenuItem.selectedProduct
              ?.customers_basket_quantity
          );

          // setTimeout(()=>setTotal(store.rest.selectedMenuItem.selectedProduct?.final_price),1000 )
          // setTotal(store.rest.selectedMenuItem.selectedProduct?.final_price)
        }

        newEntries?.map((item) => {
          if (item.products_options_id) {
            item = {
              ...item,
              options_id: item.products_options_id,
              options_values_id: item.products_options_value_id,
            };
          }

          switch (item.products_options_types_name) {
            case "Dropdown":
              setDropDown(item);
              break;
            case "Radio":
              syncRadio = {
                ...syncRadio,
                [item.options_id
                  ? item.options_id
                  : item.products_options_value_id]: item,
              };
              break;
            case "Text":
              console.log("xn xc newEntries 1", item);
              setText({
                ...item,
                products_options_values_text: item.products_options_values_text
                  ? item.products_options_values_text
                  : item.products_options_value_text,
              });
              break;
            case "Checkbox":
              const copyCheckBox = [...checkBox];
              syncChecbox.push({
                ...item,
                options_values_id: item.products_options_value_id
                  ? item.products_options_value_id
                  : item.options_values_id,
              });

              break;
            default:
              break;
          }
        });

        console.log("check item details syncChecbox", syncChecbox);
        console.log("check item details syncRa", syncRadio);

        setRadio(syncRadio);
        setCheckBox(syncChecbox);
      } else {
        dispatch(
          setMenuAddon({
            ...store.rest.itemsDetail[store.rest.selectedMenuItem.products_id],
            ...store.rest.selectedMenuItem,
          })
        );
        setTotal(Number(store.rest.selectedMenuItem.products_price));
      }
    }
    //
  }, [store.rest.selectedMenuItem]);
  console.log("checkBox", checkBox);
  if (isMobileOnly) {
    return (
      <>
        <MobileView
          data={store.rest.itemsDetail[store.rest.selectedMenuItem.products_id]}
          handleAddToCart={
            groupToken
              ? handleAddToCart
              : !store.profile.selectedAddress.address_lat
              ? () => toast.warn("Please choose an address first")
              : handleAddToCart
          }
          cartItem={cartItem}
          setRadio={setRadio}
          radio={radio}
          dropDown={dropDown}
          setDropDown={setDropDown}
          checkBox={checkBox}
          setCheckBox={setCheckBox}
          specialInstruction={specialInstruction}
          setSpecialInstruction={setSpecialInstruction}
          customers_basket_quantity={customers_basket_quantity}
          setBasketQuantity={setBasketQuantity}
          quantityLoader={quantityLoader}
          handleQuantity={handleQuantity}
          text={text}
          setText={setText}
          total={total}
          isEdit={store.rest.selectedMenuItem.isEdit}
          onChangeInput={onChangeInput}
          isItemNotAvailable={isItemNotAvailable}
          limitExceedMessage={limitExceedMessage}
          store={store}
          dispatch={dispatch}
        />
      </>
    );
  }
  return (
    <>
      <DesktopView
        // data={store.rest.itemsDetail[store.rest.selectedMenuItem.products_id]}
        // data={store.rest.itemsDetail[store.rest.selectedMenuItem.products_id]}
        data={store.rest.itemsDetail[store.rest.selectedMenuItem.products_id]}
        cartItem={cartItem}
        setRadio={setRadio}
        radio={radio}
        dropDown={dropDown}
        setDropDown={setDropDown}
        checkBox={checkBox}
        setCheckBox={setCheckBox}
        specialInstruction={specialInstruction}
        setSpecialInstruction={setSpecialInstruction}
        customers_basket_quantity={customers_basket_quantity}
        setBasketQuantity={setBasketQuantity}
        quantityLoader={quantityLoader}
        handleQuantity={handleQuantity}
        text={text}
        setText={setText}
        total={total}
        // handleAddToCart={handleAddToCart}
        handleAddToCart={
          groupToken
            ? handleAddToCart
            : !store.profile.selectedAddress.address_lat
            ? () => toast.warn("Please choose an address first")
            : handleAddToCart
        }
        clearData={clearData}
        setRerender={props.setRerender}
        {...props}
        store={store}
        dispatch={dispatch}
        isEdit={store.rest.selectedMenuItem.isEdit}
        onChangeInput={onChangeInput}
        isItemNotAvailable={isItemNotAvailable}
        limitExceedMessage={limitExceedMessage}
      />
    </>
  );
};

export default withRouter(ItemDetail);
