import {
	GET_RESTURANT_DATA,
	GET_CUISINES_DATA,
	GET_RESTURANT_MENU,
	SET_SELECTED_RESTURANT,
	RESTURANT_ID,
	SET_UNSELECTED_RESTURANT,
	GET_ITEM_ADDON,
	SELECTED_MENU_ITEM,
	REST_TIME_SELECTION,
	SET_GROUP_ORDER,
	SET_GROUP_ORDER_INFO,
	SET_SELECTED_GROUP_ORDER_ITEM,
} from "./restType";
import { toast } from "react-toastify";
import moment from "moment";
import { getTimeStops } from "../../service/common";

import { headerTabSelected, setLoader } from "../Auth/authAction";
import { APIUtils, ApiNames } from "service";
import { chekIfMenuOpen } from "service/common";
import { setGroupCartItems } from "redux-store/Cart/cartAction";
import {
	selectDate,
	selectedAddress,
	selectTime,
} from "redux-store/Profile/profileAction";

const publicIp = require("public-ip");

export const setResturants = (payload) => {
	return {
		type: GET_RESTURANT_DATA,
		payload: payload,
	};
};
export const setResturantId = (payload) => {
	return {
		type: RESTURANT_ID,
		payload: payload,
	};
};
export const setCuisines = (payload) => {
	return {
		type: GET_CUISINES_DATA,
		payload: payload,
	};
};
export const setResturantMenu = (payload) => {
	return {
		type: GET_RESTURANT_MENU,
		payload: payload,
	};
};

export const setSelectedRest = (payload) => {
	return {
		type: SET_SELECTED_RESTURANT,
		payload: payload,
	};
};
export const setUnSelectedRest = (payload) => {
	return {
		type: SET_UNSELECTED_RESTURANT,
		payload: payload,
	};
};
export const selectedMenuItem = (payload) => {
	return {
		type: SELECTED_MENU_ITEM,
		payload: payload,
	};
};

export const setMenuAddon = (payload) => {
	return {
		type: GET_ITEM_ADDON,
		payload: payload,
	};
};

export const setRestaurantTimings = (payload) => {
	return {
		type: REST_TIME_SELECTION,
		payload: payload,
	};
};
export const setGroupOrder = (payload) => {
	return {
		type: SET_GROUP_ORDER,
		payload: payload,
	};
};

export const setGroupOrderInfo = (payload) => {
	return {
		type: SET_GROUP_ORDER_INFO,
		payload: payload,
	};
};

export const setSelectedGroupOrderItem = (payload) => {
	return {
		type: SET_SELECTED_GROUP_ORDER_ITEM,
		payload: payload,
	};
};

export const getResturantsData = (data, scb, ecb) => (dispatch, getState) => {
	const store = getState();
	const params = new URLSearchParams();
	let service_type;
	let asap_or_advance;
	let date;
	let time;

	if (!data?.service_type) {
		service_type = store.auth.headerTab == "Delivery" ? 1 : 2;
		data = { ...data, service_type };
	}
	if (!data?.asap_or_advance) {
		asap_or_advance = store.profile.deliveryDate ? 2 : 1;
		data = { ...data, asap_or_advance };
	}
	// if (!data?.date) {
	//   alert("cal1")
	date = store.profile.deliveryDate;
	// }
	// if (!data?.time) {
	//   alert("cal2")
	time = moment(store.profile.deliveryTime, ["h:mm A"]).format("HH:mm");
	// }

	asap_or_advance === 2
		? (data = {
				...data,
				date,
				time,
		  })
		: (data = {
				...data,
		  });

	for (const key in data) {
		if (Object.hasOwnProperty.call(data, key)) {
			const element = data[key];
			if (element) params.append("" + key, "" + element);
		}
	}

	dispatch(setLoader(true));
	APIUtils.get(ApiNames.GET_RESTURANTS_DATA, { params })
		.then((response) => {
			const newArr = [...response.data.data];
			let ax = newArr.sort(function (a, b) {
				// Turn your strings into dates, and then subtract them
				// to get a value that is either negative, positive, or zero.
				return (
					new Date("May 26, 2016 " + b.closing_time) -
					new Date("May 26, 2016 " + a.closing_time)
				);
			});
			let bx = newArr.sort(function (a, b) {
				// Turn your strings into dates, and then subtract them
				// to get a value that is either negative, positive, or zero.
				return (
					new Date("May 26, 2016 " + b.opening_time) -
					new Date("May 26, 2016 " + a.opening_time)
				);
			});

			// dispatch()
			let open = ax[ax.length - 1].opening_time;
			let close = bx[ax.length - 1].closing_time;
			let slot_time_gap = 15;
			if (
				response &&
				response.data &&
				response.data.configurations &&
				response.data.configurations.slot_time_gap
			) {
				slot_time_gap = response.data.configurations.slot_time_gap;
			}
			let intervalTime = getTimeStops(
				ax[ax.length - 1].opening_time,
				bx[ax.length - 1].closing_time,
				slot_time_gap,
			);

			let newArrQ = [];

			for (let i = 0; i < intervalTime.length; i++) {
				// newArrQ.push(`${intervalTime[i]} - ${intervalTime[i+1]}`)
				if (i == intervalTime.length - 1) {
					let x = moment(bx[ax.length - 1].closing_time, "hh:mm").format("LT");
					newArrQ.push(`${intervalTime[i]} - ${x}`);
				} else {
					newArrQ.push(`${intervalTime[i]} - ${intervalTime[i + 1]}`);
				}
			}

			dispatch(
				setRestaurantTimings({
					timeArray: intervalTime,
					timeArrayNew: newArrQ,
				}),
			);

			dispatch(setResturants(response.data.data));
			if (scb) {
				scb();
			}
			if (!response.data.data.length) {
				toast.error("Sorry! we do not serve here try choosing other location");
			} else {
				// if (store.rest.restaurant_id) {
				let isAvailable = response.data.data.find(
					(item) => item.restaurant_id == store.rest.restaurant_id,
				);
				if (isAvailable) dispatch(setSelectedRest(isAvailable));
				// }
			}

			if (data?.restaurant_id) {
				dispatch(setSelectedRest(response.data.data[0]));
				dispatch(setResturantId("" + data?.restaurant_id));
			}

			dispatch(setLoader(false));

			//
			//toast.success(response.data.message)
		})
		.catch((error) => {
			if (ecb) {
				ecb();
			}
			dispatch(setLoader(false));
			// toast.error(error.response.data.message)
		});
};
export const getCuisinesData =
	(data, cb = () => null) =>
	(dispatch) => {
		dispatch(setLoader(true));
		APIUtils.get(ApiNames.GET_CUISINES_DATA)
			.then((response) => {
				dispatch(setLoader(false));
				dispatch(setCuisines(response.data.data));
				cb(false);
				//toast.success(response.data.message)
			})
			.catch((error) => {
				cb(false);
				dispatch(setLoader(false));

				// toast.error(error.response.data.message)
			});
	};

export const getResturantsMenu =
	(data, isCheck) => async (dispatch, getState) => {
		dispatch(setLoader(true));
		const store = getState();
		// let service_type = "0"
		let service_type = store.auth.headerTab == "Delivery" ? 1 : 2;
		// let asap_or_advance = store.profile.deliveryDate ? 2 : 1
		let date = store.profile.deliveryDate;
		let time = moment(store.profile.deliveryTime, ["h:mm A"]).format("HH:mm");
		store.profile.deliveryDate
			? (data = {
					...data,
					service_type,
					date,
					time,
			  })
			: (data = {
					...data,
					service_type,
			  });
		// let asap_or_advance = store.profile.deliveryDate ? 2 : 1
		data = { ...data, service_type };
		let ip = null;
		try {
			ip = await publicIp.v4();
		} catch (error) {
			ip = null;
		}

		const params = new URLSearchParams();
		for (const key in data) {
			if (Object.hasOwnProperty.call(data, key)) {
				const element = data[key];
				if (element) params.append("" + key, "" + element);
			}
		}

		if (ip) params.append("ip_address", "" + ip);

		APIUtils.get(ApiNames.GET_RESTURANT_MENU, { params })
			.then((response) => {
				// if (!isCheck) {

				// dispatch(setSelectedRest())
				dispatch(
					setUnSelectedRest({
						name: response.data.restaurant_data.name,
						restaurant_delivery_fee: 0,
						restaurant_delivery_time: 0,
						restaurant_cuisines:
							response.data.restaurant_data.restaurant_cuisines,
						min_order: response.data.restaurant_data.min_order,
						restaurant_id: data.restaurant_id,
						service_fee: response.data.restaurant_data.service_fee,
						prep_time: response.data.restaurant_data.prep_time
							? response.data.restaurant_data.prep_time
							: 0,
						image: response.data.restaurant_data.image,
						address: response.data.restaurant_data.address,
						lat: response.data.restaurant_data.lat,
						lng: response.data.restaurant_data.lng,
						day_off_dates: response.data.restaurant_data?.special_day_off_dates
							? response.data.restaurant_data?.special_day_off_dates.split(",")
							: [],
						service_fee_type: response.data.restaurant_data.service_fee_type,
						allow_special_instructions:
							response.data.restaurant_data.allow_special_instructions,
						minimum_pickup_order:
							response.data.restaurant_data.minimum_pickup_order,
						minimum_deliver_order:
							response.data.restaurant_data.minimum_deliver_order,
						// opening_time: response.data.restaurant_data.opening_time,
						// closing_time: response.data.restaurant_data.closing_time
					}),
				);
				// }


	

				let x = response.data.data.map((item, index) => {

					let input = []
					if(Array.isArray(item.submenus)){
						input = [...item.submenus];
					}
					let output = input.filter((obj) =>{
						return Object.keys(obj).includes("products")
					}
						
					);
					if(output.length){
						return { ...item, submenus: output };
					}
					return {}
				});

				x = x.filter(fil => Object.keys(fil).length)

		
				dispatch(
					setResturantMenu({
						restaurant_id: data.restaurant_id,
						value: x,
					}),
				);

				dispatch(
					setGroupOrder({
						group_order_id: response.data.restaurant_data.group_order_id,
						group_order_link: response.data.restaurant_data.group_order_link,
					}),
				);

				dispatch(setLoader(false));
				//cb()
				//toast.success(response.data.message)
			})
			.catch((error) => {
				dispatch(setLoader(false));
				// toast.error(error.response.data.message)
			});
	};

export const getItemAddon =
  (data, cb = () => null, fn = () =>null,  isGroupOrderItemAvailable) =>
  (dispatch, getState) => {
    const store = getState();
    dispatch(setLoader(true));
    // APIUtils.get(`${ApiNames.ITEM_ADDON}${27}`)

    
    let isDisabled = chekIfMenuOpen(
      data?.opening_time,
      data?.closing_time,
      store.profile.deliveryDate ? store.profile.deliveryDate : null,
      store.profile.deliveryDate ? store.profile.deliveryTime : null
    );
    console.log("data?.opening_time xx", data?.opening_time,
    data?.closing_time,
    store.profile.deliveryDate ? store.profile.deliveryDate : null,
    store.profile.deliveryDate ? store.profile.deliveryTime : null)

    if (!isDisabled) {
      isDisabled =
        store.rest.selectedResturantData[store.rest.restaurant_id]
          ?.rest_status == 1
          ? isDisabled
          : true;
    }

    if(isGroupOrderItemAvailable){
      isDisabled = isGroupOrderItemAvailable
    }
  
    APIUtils.get(`${ApiNames.ITEM_ADDON}${data?.products_id}`)
      .then((response) => {
				console.log("data of addon ", data)
				console.log("data of addon response", response)
        dispatch(
          setMenuAddon({
            ...data,
            ...response.data.data[0],
            isDisabled: isDisabled,
          })
        );
        cb({
          ...data,
          ...response.data.data[0],
          isDisabled: isDisabled,
        });
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

export const initiateGroupOrder =
	(data, scb = () => null, ecb = () => null) =>
	(dispatch, getState) => {
		const store = getState();
		let restId, lat, lng, service_type, asap_or_advance, date, time;
		if (data?.date) date = data?.date;
		else date = store.profile.deliveryDate;

		if (data?.time) time = data?.time;
		else time = moment(store.profile.deliveryTime, ["h:mm A"]).format("HH:mm");

		if (data?.restaurant_id) restId = data?.restaurant_id;
		else restId = store.rest.restaurant_id;

		if (data?.lat) lat = data?.lat;
		else lat = store.rest.selectedResturantData[restId].lat;

		if (data?.lng) lng = data?.lng;
		else lng = store.rest.selectedResturantData[restId].lng;

		if (data?.service_type) service_type = data?.service_type;
		else service_type = store.auth.headerTab === "Delivery" ? 1 : 2;

		if (data?.asap_or_advance) asap_or_advance = data?.asap_or_advance;
		else asap_or_advance = store.profile.deliveryDate ? 2 : 1;

		let groupOrderId = store.rest?.groupOrderInfo?.group_order_id;

		let model = {
			id: data.id,
			title: data.name,
			amount_limit: data.limit,
			members: JSON.stringify(
				data.member.map((it) => ({
					id: it.id,
					name: it.name,
					email: it.email,
					phone: it.phone,
					resend_invite: it.reinvite ? 1 : 0,
				})),
			),
			restaurant_id: restId,
			address_lat: "" + lat,
			address_lng: "" + lng,
			service_type: service_type,
			asap_or_advance: asap_or_advance,
			selected_date: `${date} ${time}`,
		};
		if (groupOrderId) {
			model["id"] = groupOrderId;
		}

		dispatch(setLoader(true));

		APIUtils.post(`${ApiNames.GROUP_ORDER_INITIATE}`, model)
			.then((response) => {
				scb(response.data.data);
				dispatch(setResturantId(restId));
				dispatch(headerTabSelected(service_type === 1 ? "Delivery" : "Pickup"));
				if (asap_or_advance === 2) {
					dispatch(selectDate(date));
					dispatch(selectTime(time));
				} else {
					dispatch(selectDate(""));
					dispatch(selectTime(""));
				}

				dispatch(setLoader(false));
			})
			.catch((error) => {
				toast.error(error.response.data.message);
				dispatch(setLoader(false));
				ecb();
			});
	};

export const getContactList =
	(cb = (data = null) => null) =>
	(dispatch) => {
		dispatch(setLoader(true));

		APIUtils.get(`${ApiNames.GET_CONTACTS_LIST}`)
			.then((response) => {
				cb(response.data.data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				dispatch(setLoader(false));
			});
	};

export const getGroupMembers =
	(id, cb = (data = null) => null) =>
	(dispatch) => {
		dispatch(setLoader(true));

		APIUtils.get(`${ApiNames.GET_GROUP_MEMBERS_LIST}/${id}`)
			.then((response) => {
				cb(response.data.data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				dispatch(setLoader(false));
			});
	};

export const getGroupOrderInfo =
	(token, cb = () => null, ecb = () => null) =>
	(dispatch, getState) => {
		dispatch(setLoader(true));
		APIUtils.get(`${ApiNames.GET_GROUP_ORDER_INFO}/${token}`)
			.then((response) => {
				console.log("response check response=>", response)
				let data = response.data.data;
				dispatch(setGroupOrderInfo(data));
				dispatch(setResturantId(data.restaurant_id));
				dispatch(
					headerTabSelected(data.service_type === 1 ? "Delivery" : "Pickup"),
				);
				if (data.asap_or_advance === 2) {
					let dateArr = data.selected_date.split(" ");
					dispatch(selectDate(dateArr[0]));
					dispatch(selectTime(dateArr[1]));
				} else {
					dispatch(selectDate(""));
					dispatch(selectTime(""));
				}
				cb(data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				ecb();
				dispatch(setLoader(false));
			});
	};

export const getGroupOrderBasket =
  (token, cb = (data = null) => null) =>
  (dispatch) => {
    dispatch(setLoader(true));

    APIUtils.get(`${ApiNames.GET_GROUP_ORDER_BASKET}/${token}`)
      .then((response) => {
        console.log("getGroupOrderBasket", response)
        let data = response?.data?.data;
        dispatch(setGroupCartItems({ items: data }));
        cb(data);
        dispatch(setLoader(false));
      })
      .catch((error) => {
        dispatch(setLoader(false));
      });
  };

export const groupOrderAddToCart =
	(model, cb = (data = null) => null, ecb = (data = null) => null) =>
	(dispatch) => {
		dispatch(setLoader(true));

		APIUtils.post(`${ApiNames.ADD_TO_CART_GROUP_ORDER}`, model)
			.then((response) => {
				let data = response?.data?.data;
				cb(data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				ecb(error.response.data?.message);
				dispatch(setLoader(false));
			});
	};

export const removeGroupOrderItem =
	(model, cb = (data = null) => null, ecb = (data = null) => null) =>
	(dispatch) => {
		dispatch(setLoader(true));
		APIUtils.put(`${ApiNames.REMOVE_ITEM_FROM_GROUP_CART}`, model)
			.then((response) => {
				let data = response?.data?.data;
				cb(data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				ecb(error.response.data?.message);
				dispatch(setLoader(false));
			});
	};

export const removeGroupMember =
	(
		group_id,
		member_id,
		cb = (data = null) => null,
		ecb = (data = null) => null,
	) =>
	(dispatch) => {
		dispatch(setLoader(true));
		APIUtils.del(`${ApiNames.REMOVE_GROUP_MEMBER}${group_id}/${member_id}`)
			.then((response) => {
				let data = response?.data?.data;
				cb(data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				ecb(error.response.data?.message);
				dispatch(setLoader(false));
			});
	};

export const saveGroupOrderCart =
	(member_token, cb = (data = null) => null, ecb = (data = null) => null) =>
	(dispatch) => {
		dispatch(setLoader(true));
		APIUtils.post(`${ApiNames.SAVE_GROUP_CART}`, { member_token })
			.then((response) => {
				let data = response?.data?.data;
				cb(data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				ecb(error.response.data?.message);
				dispatch(setLoader(false));
			});
	};

export const cancelGroupOrder =
	(member_token, cb = (data = null) => null, ecb = (data = null) => null) =>
	(dispatch) => {
		dispatch(setLoader(true));
		APIUtils.get(`${ApiNames.CANCEL_GROUP_ORDER}${member_token}`)
			.then((response) => {
				let data = response?.data;
				cb(data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				ecb(error.response.data?.message);
				dispatch(setLoader(false));
			});
	};

export const getEmptyUsers =
	(member_token, cb = (data = null) => null, ecb = (data = null) => null) =>
	(dispatch) => {
		dispatch(setLoader(true));
		APIUtils.get(`${ApiNames.GROUP_ORDER_EMPTY_MEMBERS}${member_token}`)
			.then((response) => {
				let data = response?.data;
				cb(data);
				dispatch(setLoader(false));
			})
			.catch((error) => {
				ecb(error.response.data?.message);
				dispatch(setLoader(false));
			});
	};
